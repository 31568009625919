export default class Hero {
  constructor() {
    this.wrapperChildren = $("#content").children(
      ".hero-promo, .text-block, .tools-promos",
    )
    this.wrapperChildrenLength = this.wrapperChildren.length

    this.projectChildren = $(".promo, .content")
    this.projectChildrenLength = this.projectChildren.length

    if ($(".down-arrow:not(.project-arrow)").length > 0) {
      this.addViewportClasses()

      $(window).on("scroll", () => {
        this.addViewportClasses()
      })

      $(".down-arrow").on("click", () => {
        this.slideToNextHero()
      })
    }

    if ($(".project-arrow").length > 0) {
      this.addViewportClasses(true)

      $(window).on("scroll", () => {
        this.addViewportClasses(true)
      })

      $(".project-arrow").on("click", () => {
        this.slideToNextHero(true)
      })
    }
  }

  addViewportClasses(isProject) {
    const viewportRect = document.body.getBoundingClientRect()

    if (isProject) {
      this.projectChildren.each((index, child) => {
        const childTop = $(child).offset().top,
          viewportBottom = -viewportRect.top + 200

        if (
          index < this.projectChildrenLength - 1 &&
          childTop > viewportBottom
        ) {
          $(child).addClass("below")
        } else if (
          index === this.projectChildrenLength - 1 &&
          childTop > viewportBottom + viewportRect.height - 300
        ) {
          $(child).addClass("below")
        } else {
          $(child).removeClass("below")
        }
      })

      if (!this.projectChildren.hasClass("below")) {
        $(".project-arrow").fadeOut(200)
      } else {
        $(".project-arrow").fadeIn(200)
      }
    } else {
      if (
        this.wrapperChildren.first().hasClass("glass-hero") &&
        -viewportRect.top < 200
      ) {
        $(".down-arrow").addClass("dark")
      } else {
        $(".down-arrow").removeClass("dark")
      }

      this.wrapperChildren.each((index, child) => {
        const childTop = $(child).offset().top,
          viewportBottom = -viewportRect.top + 200

        if (
          index < this.wrapperChildrenLength - 1 &&
          childTop > viewportBottom
        ) {
          $(child).addClass("below")
        } else if (
          index === this.wrapperChildrenLength - 1 &&
          childTop > viewportBottom + viewportRect.height - 300
        ) {
          $(child).addClass("below")
        } else {
          $(child).removeClass("below")
        }
      })

      if (!this.wrapperChildren.hasClass("below")) {
        $(".down-arrow").fadeOut(200)
      } else {
        $(".down-arrow").fadeIn(200)
      }
    }
  }

  slideToNextHero(isProject) {
    if (isProject) {
      if (this.projectChildren.not(".below").last().length) {
        const currentHero = this.projectChildren.not(".below").last(),
          nextHeroTop = currentHero.next().offset().top

        $("html, body")
          .stop()
          .animate({ scrollTop: nextHeroTop }, 600, "easeOutQuad")
      } else {
        const currentHero = this.projectChildren.last().offset().top

        $("html, body")
          .stop()
          .animate({ scrollTop: currentHero }, 600, "easeOutQuad")
      }
    } else {
      if (this.wrapperChildren.not(".below").last().length) {
        const currentHero = this.wrapperChildren.not(".below").last(),
          nextHeroTop = currentHero.next().offset().top

        $("html, body")
          .stop()
          .animate({ scrollTop: nextHeroTop }, 600, "easeOutQuad")
      } else {
        const currentHero = this.wrapperChildren.last().offset().top

        $("html, body")
          .stop()
          .animate({ scrollTop: currentHero }, 600, "easeOutQuad")
      }
    }
  }
}
